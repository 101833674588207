import React, { useContext, useEffect } from 'react';
import './PrivacyTextModal.scss';

import ApiController from 'providers/ApiController/ApiController';

import { ReactComponent as MenuCloseIcon } from 'assets/icons/globals/menu-close.svg';
import PrivacyTextContext from 'context/PrivacyContext'

// import { Container } from './styles';

function PrivacyTextModal() {
  const { open, setOpen, text } = useContext(PrivacyTextContext)

  useEffect(() => {
    let el = document.querySelector('#privacyModal')
    el.innerHTML = text ?? " "
  }, [text])

  return (
    <div className={open ? 'modal-privacy active' : 'modal-privacy'}>
        <div className="modal-privacy--wrapper">
          <button
            type="button"
            className="modal-privacy--wrapper_close"
            title="Fechar modal"
            aria-label="Fechar modal"
            onClick={() => {
              document.location.hash = '';
              setOpen(false)
            }
            }
          >
            <MenuCloseIcon />
          </button>

          <div className="modal-privacy--wrapper_title">Política de Privacidade</div>

          <div id='privacyModal' className="modal-privacy--wrapper_content">
              
          </div>
        </div>
      </div>
  );
}

export default PrivacyTextModal;