import React from 'react';
import './Header.scss';

import { isMobile } from 'react-device-detect';

import RenderDesktop from './Render-Desktop';
import RenderMobile from './Render-Mobile';

const Header = () => {
  /** @var JSX */
  const RenderComponent = isMobile
    ? RenderMobile
    : RenderDesktop;

  return <RenderComponent />;
}

export default Header;
