import React from 'react';
import './Modal.scss';

const Modal = ({ id = 'Modal', onClose = () => {isVisible = false}, children, isVisible = false, closeClass}) => {
    const handleClickOut = (e) => {
        if(e.target.id === id) onClose();
    }

    return (
        <div id={id} className="containerModal" style={ isVisible ? { display:'block'} : {display : 'none'} }   onClick={handleClickOut}>
            <div className="modalBox">
                <div className="ModalContent">
                    <span className={`ModalClose ${closeClass}`} onClick={onClose}>x</span>
                    <div className="Content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;