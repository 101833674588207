import React, { useState, useEffect, useContext } from 'react';
import './Footer.scss';
import Mtn from 'providers/Util/Mtn';

import { isMobile } from 'react-device-detect';
import { ScrollToHOC } from 'react-scroll-to';
import {
  Link,
  withRouter,
  useLocation,
} from 'react-router-dom';

import ApiController from 'providers/ApiController/ApiController';

import InfoBlock from './components/InfoBlock';
import Form, { Field } from 'components/Form/Form';
import ContactBar from 'components/ContactBar/ContactBar';

import BackgroundImage from 'assets/img/prod/globals/footer-background.jpg';

import ScrollToTopIcon from 'assets/img/prod/icons/scroll-to-top.png';
import AwardImage from 'assets/img/prod/globals/footer-award.png';
import Popup from 'components/Popup';

import { ReactComponent as MenuCloseIcon } from 'assets/icons/globals/menu-close.svg';
import { ReactComponent as FormSendIcon } from 'assets/icons/globals/form-send.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/globals/social-facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/globals/social-instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/globals/social-linkedin.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/globals/social-youtube.svg';
import { ReactComponent as PinterestIcon } from 'assets/icons/globals/social-pinterest.svg';
import PrivacyTextContext from 'context/PrivacyContext'
import PrivacyTextCheckbox from 'components/PrivacyTextModal/CheckBoxPrivacy'

import ReCAPTCHA from 'react-google-recaptcha';
import siteKey from '../../recaptchaKey'

const Footer = props => {
  const [captcha, setCaptcha] = useState(false);
  const [modalPrivacy, setModalPrivacy] = useState();
  const [modalQuality, setModalQuality] = useState();
  const [privacyText, setPrivacyText] = useState('')
  const { setOpen, open, setText } = useContext(PrivacyTextContext)

  const [checkPrivacyText, setCheckPrivacyText] = useState(false);

  const {
    history: {
      location: {
        pathname,
      }
    }
  } = props;

  const location = useLocation();

  useEffect(() => {
    // modal open
    if(location.hash === "#modalOpen") {
      setModalPrivacy(true);
    }
  }, []);

  useEffect(() => {
    let Api = new ApiController() 

    const request = Api.fetch([{
      type: 'getPrivacyText',
      returnAs: 'privacy',
    }])

    request.then(res => {
      // let el = document.querySelector('#privacyModal')
      // el.innerHTML = res.result?.privacy ?? " "
      setText(res?.result?.privacy)
    })

    request.catch(err => console.log(err))
  }, [])

  /** @var object */
  const infoBlocks = {
    locations: [
      {
        title: 'Sede:',
        text: 'Av. Cauaxi, 293 • 34° andar •  Ed. Alpha Green<br/>Sala 3402 • Alphaville Industrial<br/>Barueri / SP',
        info: [
          {
            type: 'tel',
            text: '11 4193-1471',
          },
          {
            type: 'email',
            text: 'contato@ekkogroup.com.br',
          },
        ],
      },
      {
        title: 'Ekko Vendas:',
        text: 'Av. dos Autonomistas, 261<br/>Vila Yara • Osasco / SP',
        info: [
          {
            type: 'tel',
            text: '11 4624-0011',
          },
        ],
      },
      {
        title: 'Ekko Store Autonomistas:',
        text: 'Av. dos Autonomistas, 261<br/>Vila Yara • Osasco / SP',
        info: [
          {
            type: 'tel',
            text: '11 2391-3233',
          },
        ],
      },
      {
        title: 'Ekko Store Bela Vista:',
        text: 'Rua Salém Bechara, 320<br/>Bela Vista • Osasco / SP',
        info: [
          {
            type: 'tel',
            text: '11 98949-8366',
          },
        ],
      },
    ],
  }

  /** @var object */
  const menuItems = [
    {
      label: 'Fale Conosco',
      to: '/fale-conosco/fale-conosco',
    },
    {
      label: 'Trabalhe Conosco',
      to: '/fale-conosco/trabalhe-conosco',
    },
    {
      label: 'Sou Investidor',
      to: '/fale-conosco/sou-investidor',
    },
    {
     label: 'Painel de Sócios',
     to: '/Painel-Socios',
   },
    {
      label: 'Venda seu Terreno',
      to: '/fale-conosco/venda-seu-terreno',
    },
    {
      label: 'Sou Vizinho da Obra',
      to: '/fale-conosco/sou-vizinho-da-obra',
    }
  ];

  /** @var object */
  const socialLinks = [
    {
      title: 'Curta no Facebook',
      icon: <FacebookIcon />,
      href: 'https://facebook.com/EkkoGroupOficial',
    },
    {
      title: 'Siga no Instagram',
      icon: <InstagramIcon />,
      href: 'https://instagram.com/ekkogroupoficial',
    },
    {
      title: 'Siga no Linkedin',
      icon: <LinkedinIcon />,
      href: 'https://linkedin.com/company/ekko-group',
    },
    {
      title: 'Inscreva-se no YouTube',
      icon: <YouTubeIcon />,
      href: 'https://youtube.com/channel/UC3A-0goMs8jghJYllDumRVA',
    },
    {
      title: 'Siga no Pinterest',
      icon: <PinterestIcon />,
      href: 'https://br.pinterest.com/ekkogroupoficial/',
    },
  ];

  /**
   * handleFormSubmit
   *
   * Handles the form submit.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object data
   * @return Promise
   */
  const handleFormSubmit = async (data) => {
    const apiCtrl = new ApiController();

    let requestData = {
    ...data,
      privacidade: checkPrivacyText ? '1' : '0'
    }

    const response = await apiCtrl.sendForm(1316, requestData);
    return {
      success: response.success,
      reset: true,
      message: response.success
        ? 'Cadastro realizado!'
        : 'Não foi possível enviar a sua mensagem.',
    }
  }

  const handleCaptcha = d => {
    if (typeof d === 'string') setCaptcha(true);
  }

  // render
  return (
    <footer id="mainFooter">  
     <button
        type="button"
        className="scroll-to-top"
        onClick={() => props.scroll({ y: 0, smooth: true})}>
        <img
          src={ScrollToTopIcon}
          alt="Ir para o topo"
          className="el-img"
        />
        <span>Ir para<br /> o topo</span>
      </button>

      <div className="background">
        <img
          src={BackgroundImage}
          alt="Ekko Group"
          className="el-img el-img__cover"
        />
      </div>

      <div className="container">
        <div className="row row-info">
          <div className="col">
            <h3 className="col__title">
              Onde estamos
            </h3>

            <div className="col__content">
              {infoBlocks.locations.map((block, key) => (
                <InfoBlock key={key} {...block} />
              ))}
            </div>
          </div>

          <div className="col">
            <h3 className="col__title">
              Premiações
            </h3>

            <div className="col__content">
              <div className="award">
                <img
                  src={AwardImage}
                  alt="Prêmio Master Imobiliário"
                  className="el-img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row row-menu">
          <nav className="menu">
            <ul>
              {menuItems.map((item, key) => (
                <li
                  key={key}
                  className="item">
                  {item.external ? (
                    <a
                      href={item.to}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="item__link">
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      to={item.to}
                      className="item__link">
                      {item.label}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="row row-contact">
          <div className="col col-newsletter">
            <h3 className="col__title">
              Assine nossa newsletter
            </h3>

            <div className="col__content">
              <div className="message">Cadastre-se e receba novidades<br /> da Ekko Group</div>
              <Form
                uniqueId="footerNewsletterForm"
                onSubmit={handleFormSubmit}
                Formcaptcha={captcha}
                AcceptPrivacyText={checkPrivacyText}
              >
                <Field
                  name="nome"
                  placeholder="Nome"
                  required
                />
                <Field
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  required
                />
                <div className="form__submit">
                  <button
                    type="submit"
                    className="submit-button">
                    <FormSendIcon />
                  </button>
                </div>

                <div className="recaptcha">
                  {/* <ReCAPTCHA sitekey={siteKey} onChange={handleCaptcha} /> */}

                  <PrivacyTextCheckbox handle={setCheckPrivacyText} value={checkPrivacyText} />
                </div>
              </Form>
            </div>
          </div>

          <div className="col col-social-links">
            <h3 className="col__title">
              Siga e compartilhe
            </h3>

            <div className="col__content">
              <nav className="social-links">
                <ul>
                  {socialLinks.map((item, key) => (
                    <li
                      key={key}
                      className="item">
                      <a
                        href={item.href}
                        title={item.title}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="item__link">
                        {item.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="row row-copyright">
          <div className="copyright">
            © {(new Date()).getFullYear()} • Ekko Group • Todos os direitos reservados • All rights reserved

            <button
              type="button"
              title="Abrir modal"
              aria-label="Abrir modal"
              onClick={() => setModalQuality(true)}
            >
              Política do Sistema de Gestão Integrado
            </button>

            <button
              type="button"
              title="Abrir modal"
              aria-label="Abrir modal"
              onClick={() => setOpen(true)}
            >
              Política de Privacidade
            </button>
          </div>
          
        </div>
      </div>

      <Popup setter={setModalPrivacy} />

      {isMobile && pathname.indexOf('/empreendimentos/post') === -1 && <ContactBar />}

      {/* <div className={modalPrivacy ? 'modal-privacy active' : 'modal-privacy'}>
        <div className="modal-privacy--wrapper">
          <button
            type="button"
            className="modal-privacy--wrapper_close"
            title="Fechar modal"
            aria-label="Fechar modal"
            onClick={() => {
              document.location.hash = '';
              setModalPrivacy(false);
            }
            }
          >
            <MenuCloseIcon />
          </button>

          <div className="modal-privacy--wrapper_title">Política de Privacidade</div>

          <div id='privacyModal' className="modal-privacy--wrapper_content">
              
          </div>
        </div>
      </div> */}

      <div className={modalQuality ? 'modal-quality active' : 'modal-quality'}>
        <div className="modal-privacy--wrapper">
          <button
            type="button"
            className="modal-quality--wrapper_close"
            title="Fechar modal"
            aria-label="Fechar modal"
            onClick={() => setModalQuality(false)}
          >
            <MenuCloseIcon />
          </button>

          <div className="modal-quality--wrapper_title">Política do Sistema de Gestão Integrado</div>

          <div className="modal-quality--wrapper_content">
            <p>A EKKOGROUP tem como direcionamento estratégico garantir competência no desenvolvimento de 
              suas atividades de Incorporação, Coordenação de Projetos e Construção de Edificações para 
              oferecer qualidade habitacional, assumindo o compromisso de melhorar continuamente os seus
               processos com o objetivo de superar expectativas e garantir a satisfação de seus clientes, 
               considerando ainda:</p>

            <p>· Atuar com responsabilidade ambiental para atendimento aos requisitos legais aplicáveis, 
              garantindo a sustentabilidade de suas atividades e contribuindo com as gerações futuras;
              <br/>
              <br/>· Zelar pela saúde e segurança de seus colaboradores no desenvolvimento de suas atividades, 
              promovendo motivação e produtividade.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
Mtn.createQueryString();
export default ScrollToHOC(withRouter(Footer));