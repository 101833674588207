import React, {useState} from 'react';
import './ContactBar.scss';
import Mtn from 'providers/Util/Mtn';
import Modal from 'components/Modal/Modal';
import Form, { Field } from 'components/Form/Form';

import { Link } from 'react-router-dom';
import siteKey from '../../recaptchaKey'

import ReCAPTCHA from 'react-google-recaptcha';
import EmailIcon from 'assets/img/prod/globals/mobile-actions-email.png';
import CallIcon from 'assets/img/prod/globals/mobile-actions-call.png';
import ChatIcon from 'assets/img/prod/globals/mobile-actions-chat.png';
import WhatsappIcon from 'assets/img/prod/globals/mobile-actions-whatsapp.png';
import PrivacyTextCheckbox from 'components/PrivacyTextModal/CheckBoxPrivacy'

const ContactBar = () => {
  const [isModalVisible, setModalVisible] = useState(false);

const [captcha, setCaptcha] = useState(false)
const handleCaptcha = () => {
  setCaptcha(!captcha)
}

const [checkPrivacyText, setCheckPrivacyText] = useState(false)


  const chatRun = () => {

    // if(!captcha) {
    //   return alert('Preencha o captcha')
    // }

    let nome = document.querySelector('[name="chat-nome"]').value;
    let email = document.querySelector('[name="chat-email"]').value;
    let telefone = document.querySelector('[name="chat-telefone"]').value;
    let regParent = /\(([^)]+)\)/;
    let regTra = /-/;
    let ddd = regParent.exec(telefone)[1];
    let number = telefone.split(" ")[1].replace(regTra, '');
    let idProduto = document.getElementById('hypnoboxid');
    let hypnoId='20';
    if(idProduto){
      hypnoId = idProduto.value;
    }
    let origin = Mtn.getRdMediumInfo().source;

    let href = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=entrar&nome=' + nome + '&email=' + email + '&ddd=' + email + '&telefone=' + number + '&id_produto=' + hypnoId + '&midia=' + origin;
    
    window.open(href,
                'targetWindow',
                  `toolbar=no,
                  location=no,
                  status=no,
                  menubar=no,
                  scrollbars=yes,
                  resizable=yes,
                  width=500,
                  height=500`);

    document.querySelector('.form__loader').classList.remove('open');
    setModalVisible(false);
    setCaptcha(false);
    setCheckPrivacyText(false);    
  }

  // prechat
  const [modalPreChat, setModalPreChat] = useState(false);
  const [modalPreChatAction, setModalPreChatAction] = useState(false);

  const handleWhatsapp = () => {
    setModalPreChat(false);
    let idProduto = document.getElementById('hypnoboxid');
        let hypnoId='20';
        if(idProduto){
          hypnoId = idProduto.value;
        }
        let origin = Mtn.getRdMediumInfo().source;
        let url = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=index&tipo_atendente=whatsapp&canal=whatsapp&subcanal=whatsapp&pre_chat=0&midia=' + origin + '&produto=' + hypnoId;
        window.open(
          url,
          'chat',
          'toolbar=0,status=0,width=674,height=497'
        );
  }

  return (
    <div className="mobile-contact-bar">
			<div className="action">
				<Link
					to="/fale-conosco"
					title="E-mail"
					className="action__link">
				</Link>
				<div className="action__icon">
					<img
						src={EmailIcon}
						alt="Email"
						className="el-img"
					/>
				</div>
				<div className="action__text">Fale Conosco</div>
			</div>

      <div className="action">
        <a
          href="tel:48586777"
          title="Ligar"
          className="action__link">
        </a>
        <div className="action__icon">
          <img
            src={CallIcon}
            alt="Ligar"
            className="el-img"
          />
        </div>
        <div className="action__text">Ligue</div>
      </div>

      <div className="action">
        <button
          title="Falar por WhatsApp"
          className="action__link"
          onClick={() => {
            // let idProduto = document.getElementById('hypnoboxid');
            // let hypnoId='20';
            // if(idProduto){
            //   hypnoId = idProduto.value;
            // }
            // let origin = Mtn.getRdMediumInfo().source;
            // let url = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=index&tipo_atendente=whatsapp&canal=whatsapp&subcanal=whatsapp&pre_chat=0&midia=' + origin + '&produto=' + hypnoId;
            // window.open(
            //   url,
            //   'chat',
            //   'toolbar=0,status=0,width=674,height=497'
            // );

            setModalPreChat(true);
            setModalPreChatAction("whatsapp");
          }}>
        </button>
        <div className="action__icon">
          <img
            src={WhatsappIcon}
            alt="Falar por WhatsApp"
            className="el-img"
          />
        </div>
        <div className="action__text">WhatsApp</div>
      </div>

      <div className="action">
        <button
					type="button"
          title="FAQ"
					className="action__link"
					onClick={() => {
            // setModalVisible(true);
            setModalPreChat(true);
            setModalPreChatAction("chat");
					}}>
        </button>
        <div className="action__icon">
          <img
            src={ChatIcon}
            alt="Chat"
            className="el-img"
          />
        </div>
        <div className="action__text">Chat</div>
      </div>

      {isModalVisible ? (
          <Modal onClose={() => setModalVisible(false)} isVisible={true}>
            <div className="ModalForm">
            <Form
              uniqueId="chatForm" 
              onSubmit={chatRun} 
              loaderInitialMessage="Aguarde..."
              AcceptPrivacyText={checkPrivacyText}
              >
                  <Field
                    name="chat-nome"
                    placeholder="Digite o seu nome"
                    required
                  />
                  <Field
                    type="email"
                    name="chat-email"
                    placeholder="Digite o seu e-mail"
                    required
                  />
                  <Field
                    type="phone"
                    name="chat-telefone"
                    placeholder="(00) 00000-0000"
                    required
                  />

                  {/* <div className="recaptcha">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptcha}
                    />
                  </div> */}
                  <div className="recaptcha">
                    {/* <input value={checkPrivacyText} onChange={(e) => setCheckPrivacyText(e.target.value)} type="checkbox" name="acceptTextPrivacyContactBar" id="acceptTextPrivacyContactBar" />
                    <label htmlFor="acceptTextPrivacyContactBar">Li e concordo com a <Link to="">politica de privacidade</Link></label>
                     */}
                    <PrivacyTextCheckbox handle={setCheckPrivacyText} value={checkPrivacyText} shortText={true} />
                  </div>

                <button type="submit">Entrar</button>
              </Form>
            </div>
          </Modal>
        ) : null}

        <Modal isVisible={modalPreChat} id="modalPreChat" onClose={() => setModalPreChat(false)}>
          <div className="modalPreChatContainer">
              <div className="header">
                <p><strong>Olá!</strong> <br></br >como podemos te ajudar?</p>
              </div>
              {modalPreChatAction == "chat" ? <p className="item" onClick={() => { setModalPreChat(false); setModalVisible(true);}}>Quero comprar um Ekko</p> : <p className="item" onClick={() => handleWhatsapp()}>Quero comprar um Ekko</p>}
              <a href="/fale-conosco/sou-cliente" className="item">Já sou cliente</a>
              <a href="/fale-conosco/trabalhe-conosco" className="item">Quero trabalhar na Ekko</a>
              <a href="/fale-conosco/sou-fornecedor" className="item">Quero ser fornecedor</a>
          </div>
        </Modal>
    </div>
  );
}

export default ContactBar;
