import { useState, useEffect, createContext } from 'react'
import PrivacyTextModal from '../components/PrivacyTextModal/PrivacyTextModal';

const DEFAULT_VALUES = {
  open: false,
  text: ''
}

const PrivacyTextContext = createContext(DEFAULT_VALUES)

const PrivacyContextProvider = ({ children }) => {
  const [open, setOpen] = useState(DEFAULT_VALUES.open)
  const [text, setText] = useState(DEFAULT_VALUES.text)

  const values = {
    open,
    setOpen,
    text,
    setText
  }

  return (
    <PrivacyTextContext.Provider value={values}>
      {children}
    </PrivacyTextContext.Provider>
  )
}

export { PrivacyContextProvider }
export default PrivacyTextContext