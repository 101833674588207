import React, { useContext } from 'react';

import './PrivacyTextCheckBox.scss';

import PrivacyTextContext from 'context/PrivacyContext'

function PrivacyTextCheckbox({ handle, value, secondColor, shortText=false }) {
  const { setOpen, open } = useContext(PrivacyTextContext)
  
  return (
    <>
      <input value={value} onChange={(e) => handle(e.target.value)} type="checkbox" name="acceptTextPrivacyContactBar" id="acceptTextPrivacyContactBar" />
      {
        shortText ? (
          <label htmlFor="acceptTextPrivacyContactBar">
            Termos de <span className={`privacyTextLabel ${secondColor ? 'white' : ''}`} onClick={() => setOpen(!open)}>privacidade</span>
          </label>
        ) 
        : (
          <label htmlFor="acceptTextPrivacyContactBar">
            Li e concordo com a <span className={`privacyTextLabel ${secondColor ? 'white' : ''}`} onClick={() => setOpen(!open)}>politica de privacidade</span>
          </label>
        )
      }
    </>
  );
}

export default PrivacyTextCheckbox;