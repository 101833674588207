import React from 'react';

import TelephoneIconImage from 'assets/img/prod/icons/footer-phone.png';
import EmailIconImage from 'assets/img/prod/icons/footer-email.png';

const InfoBlock = props => {
  /** @var object */
  const infoIcons = {
    tel: TelephoneIconImage,
    email: EmailIconImage,
  }

  // render
  return (
    <div className="block">
      <h4 className="block__title">
        {props.title}
      </h4>

      <div className="block__content">
        {props.text &&
          <p
            dangerouslySetInnerHTML={{ __html: props.text }}>
          </p>
        }

        {props.info &&
          <div className="block__content-info">
            <ul>
              {props.info.map((info, key) => {
                const link = info.type === 'tel'
                  ? `tel:${info.text.replace(/[\s.\-()]/g, '')}`
                  : `mailto:${info.text}`;

                return (
                  <li key={key} className="info">
                    <div className="info__content">
                      <div className="info__content-icon">
                        <img
                          src={infoIcons[info.type]}
                          alt="Ekko Group"
                          className="el-img"
                        />
                      </div>

                      <div className="info__content-text">
                        <a
                          href={link}
                          className="info__content-text__link">
                          {info.text}
                        </a>
                      </div>
                    </div>
                    {info.extra &&
                      <div className="info__extra">
                        {info.extra}
                      </div>
                    }
                  </li>
                )
              })}
            </ul>
          </div>
        }
      </div>
    </div>
  );
}

export default InfoBlock;
