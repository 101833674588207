import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'

import ReCAPTCHA from 'react-google-recaptcha';

import {
  Link,
  NavLink,
} from 'react-router-dom';

import LogoImage from 'assets/img/prod/globals/logo-desktop.png';
import Modal from 'components/Modal/Modal';
import Form, { Field } from 'components/Form/Form';
import siteKey from '../../recaptchaKey';

import { ReactComponent as ExternalIcon } from 'assets/icons/components/header/external.svg';
import { ReactComponent as ActionContactIcon } from 'assets/icons/components/header/action-email.svg';
import { ReactComponent as ActionPhoneIcon } from 'assets/icons/components/header/action-phone.svg';
import { ReactComponent as ActionChatIcon } from 'assets/icons/components/header/action-chat.svg';
import { ReactComponent as ActionWhatsappIcon } from 'assets/icons/components/header/action-whatsapp.svg';
import Mtn from 'providers/Util/Mtn';
import PrivacyTextCheckbox from 'components/PrivacyTextModal/CheckBoxPrivacy'

const Header = () => {
  /** @state boolean */
  const [isSticky, setSticky] = useState(false);
  const { pathname } = useLocation()
  const [checkPrivacyText, setCheckPrivacyText] = useState(false);
  
  // set sticky state based on
  // the scroll position
  useEffect(() => {
    const checkStickyState = () => {
      const sticky = window.scrollY > 50;
      setSticky(sticky);
    }

    // check on load
    checkStickyState();

    // add event listener
    window.addEventListener('scroll', checkStickyState);

    // cleanup
    return () => window.removeEventListener('scroll', checkStickyState);
  }, []);

  /**
   * renderMenuItem
   *
   * Renders the specified menu item.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object item
   * @param boolean isChild
   * @return JSX
   */
  const renderMenuItem = (item, isChild) => {
    const className = isChild
      ? `child-link ${pathname === "/sustentabilidade" && "menu-ekkoar" }`
      : 'menu__item-link';

    return item.external ? (
        <a
          href={item.to}
          rel="noopener noreferrer"
          target="_blank"
          className={className }>
            <span>
              {item.title}
            </span>
          <ExternalIcon />
        </a>
    ) : (
      <NavLink
        to={item.to}
        exact={item.exact}
        className={className}>
        <span>
        {item.title}
        </span>
      </NavLink>
    );
  }

  /** @var object */
  const menuItems = [
    {
      title: 'Home',
      to: '/',
      exact: true,
    },
    {
      title: 'Corretores e Imobiliárias',
      to: '/fale-conosco/corretores-e-imobiliarias',
      exact: true,
    },
    {
      title: 'Empreendimentos',
      to: '/empreendimentos',
    },
    
    {
      title: 'Quem Somos',
      to: '/quem-somos',
      exact: true,
      children: [
        {
          title: 'Ekko Group',
          to: '/quem-somos',
        },
        {
          title: 'Ekko Blog',
          to: '/blog',
        },
        {
          title: 'Ekko.ong',
          to: 'http://ekkoong.com.br',
          external: true,
        },
        {
          title: 'Ekko Vendas',
          to: '/ekko-vendas',
        },
        {
          title: 'Ekko Dekor',
          to: '/dekor',
        },
        {
          title: 'Sustentabilidade',
          to: '/sustentabilidade',
          external: true,
        },
      ],
    },
  ];

  /** @var object */
  const actionsList = [
    {
      label: 'Fale Conosco',
      icon: false,
      to: '/fale-conosco/quero-comprar',
      action:() => {
        let idProduto = document.getElementById('hypnoboxid');
        let hypnoId='20';
        if(idProduto){
          hypnoId = idProduto.value;
        }
        window.IdProduto = hypnoId;
        window.location = "/fale-conosco?idprod=" + hypnoId;
      }
    },
    {
      title: 'Entrar em contato pelo telefone',
      label: '4858 6777',
      icon: ActionPhoneIcon,
      to: 'tel:48586777',
      defaultLink: true,
    },
    {
      title: 'Chat',
      label: 'Chat',
      icon: ActionChatIcon,
      action: () => {        
        // let idProduto = document.getElementById('hypnoboxid');
        // let hypnoId='20';
        // if(idProduto){
        //   hypnoId = idProduto.value;
        // }
        // let origin = Mtn.getRdMediumInfo().source;
        // let url = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=index&midia=' + origin + '&produto=' + hypnoId;
        // window.open(
        //   url,
        //   'chat',
        //   'toolbar=0,status=0,width=674,height=497'
        // );

        setModalPreChat(true);
        setModalPreChatAction("chat");
        // setModalVisible(true);
      },
    },
		{
			title: 'Falar por WhatsApp',
			label: 'WhatsApp',
			icon: ActionWhatsappIcon,
			action: () => {
        // let idProduto = document.getElementById('hypnoboxid');
        // let hypnoId='20';
        // if(idProduto){
        //   hypnoId = idProduto.value;
        // }
        // let origin = Mtn.getRdMediumInfo().source;
        // let url = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=index&tipo_atendente=whatsapp&canal=whatsapp&subcanal=whatsapp&pre_chat=0&midia=' + origin + '&produto=' + hypnoId;
        // window.open(
        //   url,
        //   'chat',
        //   'toolbar=0,status=0,width=674,height=497'
        // );

        setModalPreChat(true);
        setModalPreChatAction("whatsapp");
			},
		}
  ];

  const handleWhatsapp = () => {
    setModalPreChat(false);
    let idProduto = document.getElementById('hypnoboxid');
        let hypnoId='20';
        if(idProduto){
          hypnoId = idProduto.value;
        }
        let origin = Mtn.getRdMediumInfo().source;
        let url = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=index&tipo_atendente=whatsapp&canal=whatsapp&subcanal=whatsapp&pre_chat=0&midia=' + origin + '&produto=' + hypnoId;
        window.open(
          url,
          'chat',
          'toolbar=0,status=0,width=674,height=497'
        );
  }

  const [ChatCaptcha, setChatCaptcha] = useState(false)
  const handleCaptcha = () => {
    setChatCaptcha(true)
  }

  const [isModalVisible, setModalVisible] = useState(false);
  const chatRun = () => {

    // if(!ChatCaptcha) {
    //   return alert('Preencha o captcha')
    // }

    let nome = document.querySelector('[name="chat-nome"]').value;
    let email = document.querySelector('[name="chat-email"]').value;
    let telefone = document.querySelector('[name="chat-telefone"]').value;
    let regParent = /\(([^)]+)\)/;
    let regTra = /-/;
    let ddd = regParent.exec(telefone)[1];
    let number = telefone.split(" ")[1].replace(regTra, '');
    let idProduto = document.getElementById('hypnoboxid');
    let hypnoId='20';
    if(idProduto){
      hypnoId = idProduto.value;
    }
    let origin = Mtn.getRdMediumInfo().source;

    let href = 'https://ekkogroup.hypnobox.com.br/?controle=Atendimento&acao=entrar&nome=' + nome + '&email=' + email + '&ddd=' + email + '&telefone=' + number + '&id_produto=' + hypnoId + '&midia=' + origin;
    window.open(href,
                'targetWindow',
                  `toolbar=no,
                  location=no,
                  status=no,
                  menubar=no,
                  scrollbars=yes,
                  resizable=yes,
                  width=500,
                  height=500`);

    document.querySelector('.form__loader').classList.remove('open');
    setModalVisible(false);
    setChatCaptcha(false);
    setCheckPrivacyText(false);
    
  }

  // prechat
  const [modalPreChat, setModalPreChat] = useState(false);
  const [modalPreChatAction, setModalPreChatAction] = useState(false);

  // render
  return (
    <header id="mainHeader" className={`desktop ${isSticky ? 'sticky' : null}`}>
      <div className="container">
        <div className={`col col-logo ${pathname === "/sustentabilidade" && "header-ekkoar" }`}>
          <Link to="/" className="logo">
            <img
              src={LogoImage + '?v=20200827'}
              alt="Ekko Group"
              className="el-img"
            />
          </Link>
        </div>

        <div className="col col-menu">
          <nav className="menu">
            <ul>
              {menuItems.map((item, itemKey) => (
                <li
                  key={itemKey}
                  className="menu__item">
                  {renderMenuItem(item)}

                  {item.children &&
                    <ul className="menu__item-children">
                      {item.children.map((child, childKey) => (
                        <li
                          key={childKey}
                          className="child">
                          {renderMenuItem(child, true)}
                        </li>
                      ))}
                    </ul>
                  }
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="col col-actions">
          <nav className="actions">
            <ul>
              {actionsList.map(
                ({ icon: Icon, ...action }, actionKey) => (
                  <li
                    key={actionKey}
                    className="actions__item">
                    {action.action ? (
                      <button
                        type="button"
                        title={action.title || null}
                        className="actions__item-link"
                        onClick={action.action}
                        style={action.label === "FAQ" ? { fontSize: '12px', margin: '0px 10px'} : null }>

                        {Icon ? <Icon /> : null }

                        {action.label}
                      </button>
                    ) : action.defaultLink ? (
                      <a
                        href={action.to}
                        title={action.title || null}
                        className="actions__item-link">
                        <Icon />
                        {action.label}
                      </a>
                    ) : (
                      <Link
                        to={action.to}
                        title={action.title || null}
                        className="actions__item-link">
                        <Icon />
                        {action.label}
                      </Link>
                    )}
                  </li>
                  )
                )}
              </ul>
            </nav>
          </div>
        </div>
        {isModalVisible ? (
          <Modal onClose={() => setModalVisible(false)} isVisible={true}>
            <div className="ModalForm">
            <Form
              uniqueId="chatForm" 
              onSubmit={chatRun} 
              Formcaptcha={ChatCaptcha} 
              loaderInitialMessage="Aguarde..."
              AcceptPrivacyText={checkPrivacyText}
            >
                  <Field
                    name="chat-nome"
                    placeholder="Digite o seu nome"
                    required
                  />
                  <Field
                    type="email"
                    name="chat-email"
                    placeholder="Digite o seu e-mail"
                    required
                  />
                  <Field
                    type="phone"
                    name="chat-telefone"
                    placeholder="(00) 00000-0000"
                    required
                  />

                  <div className="recaptcha">
                    {/* <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptcha}
                    /> */}
                    <PrivacyTextCheckbox handle={setCheckPrivacyText} value={checkPrivacyText} shortText={true} />
                  </div>

                <button type="submit">Entrar</button>
              </Form>
            </div>
          </Modal>
        ) : null}

        <Modal isVisible={modalPreChat} id="modalPreChat" onClose={() => setModalPreChat(false)}>
          <div className="modalPreChatContainer">
              <div className="header">
                <p><strong>Olá!</strong> <br></br >como podemos te ajudar?</p>
              </div>
              {modalPreChatAction == "chat" ? <p className="item" onClick={() => { setModalPreChat(false); setModalVisible(true);}}>Quero comprar um Ekko</p> : <p className="item" onClick={() => handleWhatsapp()}>Quero comprar um Ekko</p>}
              <a href="/fale-conosco/sou-cliente" className="item">Já sou cliente</a>
              <a href="/fale-conosco/trabalhe-conosco" className="item">Quero trabalhar na Ekko</a>
              <a href="/fale-conosco/sou-fornecedor" className="item">Quero ser fornecedor</a>
          </div>
        </Modal>
      </header>
    );
  }

  export default Header;
