class Mtn {
  isEmpty = o => {
    return (typeof o !== 'string' || o.length === 0);
  }
  getCookiebyOffset = offset => {
    var endstr = document.cookie.indexOf(';', offset);
    if (endstr === -1)
        endstr = document.cookie.length;
    var ret = '';
    try {
        ret = unescape(document.cookie.substring(offset, endstr));
    } catch (e) {
        ret = decodeURIComponent(document.cookie.substring(offset, endstr));
    }
    return ret;
  }
  setCookie(name, value, days, session) {
    var d = new Date();
    days = days || d.getDate() + (365);
    d.setDate(days); // guarda por um ano
    if (session)
        document.cookie = name + "=" + value + ";path=/;";
    else
        document.cookie = name + "=" + value + ';expires=' + d.toUTCString() + ";path=/;";
  }
  getCookie(name) {
    var arg = name + "=";
    var alen = arg.length;
    var clen = document.cookie.length;
    var i = 0;
    while (i < clen) {
        var j = i + alen;
        if (document.cookie.substring(i, j) === arg)
            return this.getCookiebyOffset(j);
        i = document.cookie.indexOf(' ', i) + 1;
        if (i === 0) break;
    }
    return null;
  }
  deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  }
  getQueryString = (name, defaultReturn) => {
    defaultReturn = defaultReturn || '';
    if(!window.URL_VARIABLES) {
        window.URL_VARIABLES = {};
    }
    if (window.URL_VARIABLES.hasOwnProperty && window.URL_VARIABLES.hasOwnProperty(name)) {
        return window.URL_VARIABLES[name];
    } else {
        return defaultReturn;
    }
  
  }
  createQueryString() {
    console.log('teste Mtn 2');
    window.URL_VARIABLES = {};
    var sURL = window.location.search.substring(1);
    var sURLVars = sURL.split('&');
    for (var i = 0; i < sURLVars.length; i++) {
        var item = sURLVars[i].split('=');
        window.URL_VARIABLES[item[0]] = decodeURI(item[1]);
    }
    var origin = 'Direto';
    var gClid = this.getQueryString('gclid');
    var source = this.getQueryString('utm_source');
    var campaign = this.getQueryString('utm_campaign');
    var referer = document.referrer || '';
    var refererOri = referer;
    referer = encodeURIComponent(referer);
  
    var mustCook = false;
    var isPaid = false;
  
    if (this.isEmpty(gClid) === false) {
        origin = 'google-cpc';
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(campaign)) {
        //origin = 'campanha-' + source + '-' + campaign;
        origin = 'campanha-' + source;
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(source)) {
        origin = 'campanha-' + source ;
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(source)  && source.toLowerCase().indexOf('facebook') !== -1) {
        origin = 'facebook-cpc';
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(source)  && source.toLowerCase().indexOf('instagram') !== -1) {
        origin = 'instagram-cpc';
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(source)  && source.toLowerCase().indexOf('twitter') !== -1) {
        origin = 'twitter-cpc';
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(source)  && source.toLowerCase().indexOf('linkedin') !== -1) {
        origin = 'linkedin-cpc';
        mustCook = true;
        isPaid = true;
    }
    else if (!this.isEmpty(referer) && referer.toLowerCase().indexOf('facebook') !== -1) {
        origin = 'referencia-facebook';
        mustCook = true;
    }
    else if (!this.isEmpty(referer) && referer.toLowerCase().indexOf('instagram') !== -1) {
        origin = 'referencia-instagram';
        mustCook = true;
    }
    else if (!this.isEmpty(referer) && referer.toLowerCase().indexOf('twitter') !== -1) {
        origin = 'referencia-twitter';
        mustCook = true;
    }
    else if (!this.isEmpty(referer) && referer.toLowerCase().indexOf('linkedin') !== -1) {
        origin = 'referencia-linkedin';
        mustCook = true;
    }
    else if (!this.isEmpty(referer) && referer.toLowerCase().indexOf('google') !== -1) {
       // origin = 'google-organico';
        origin = 'organico';
        mustCook = true;
    }
    else if (!this.isEmpty(referer) && refererOri.indexOf(window.location.host) === -1) {
        origin = 'referencia-' + referer;
        mustCook = true;
    }
    var cookieVal = this.getCookie('mtn-ekko-origin');
    if(cookieVal === null || mustCook === true) {
        var saveCookie = false;
        if(isPaid === true) {
            saveCookie = true;
        }
        else if(cookieVal) {
            if(cookieVal.indexOf('google-cpc') === -1 && cookieVal.indexOf('campanha') === -1) {
                saveCookie = true;
            }
        }
        else {
            saveCookie = true;
        }
        if(saveCookie){
          this.setCookie('mtn-ekko-origin', origin, '', true);
        }
    }
    console.info('Mtn origin');
    console.info(origin);
  }

  getRdMediumInfo() {
    var origin = this.getCookie('mtn-ekko-origin');
    var isCpc = (origin.indexOf('cpc') !== -1 ||  origin.indexOf('campanha')  !== -1);
    //var isDirect = origin.indexOf('Direto') !== -1;
    var isReferal = origin.indexOf('referencia') !== -1;
    var isOrganic = origin.indexOf('organico') !== -1;
    var isSocial = (origin.indexOf('facebook') !== -1 ||  origin.indexOf('instagram')  !== -1 ||  origin.indexOf('twitter')  !== -1 ||  origin.indexOf('linkedin')  !== -1);

    var retObj = {
        medium:'',
        source:origin
    }
    if(isCpc) {
        retObj.source = origin;
        retObj.medium = 'cpc';
        if(isSocial)
            retObj.medium = 'social';
    } 
    else if(isSocial) {
        retObj.source = origin;        
        retObj.medium = 'social';
    }
    else if(isReferal) {
        retObj.source = origin;        
        retObj.medium = 'referral';
    }
    else if(isOrganic) {
        retObj.source = origin;        
        retObj.medium = 'organic';
    }
    retObj.c_utmz= '43135652.1441366582.1.1.utmcsr=(' + retObj.source + ')|utmccn=|utmcmd=' + retObj.medium + '';
    return retObj;
}

}

export default new Mtn();
