import { lazy } from 'react';

const HomePage = lazy(() => import('routes/Home/Home'));
const FAQ = lazy(() => import('routes/FAQ/faq'));
const AboutPage = lazy(() => import('routes/About/About'));
const AboutImobiliariasPage = lazy(() => import('routes/AboutImobiliarias/AboutImobiliarias'));
const AboutArk = lazy(() => import('routes/AboutArk/AboutArk'));
const AboutDekor = lazy(() => import('routes/AboutDekor/AboutDekor'));
const BlogPage = lazy(() => import('routes/Blog/Blog'));
const BlogPostPage = lazy(() => import('routes/BlogPost/BlogPost'));
const ProjectsPage = lazy(() => import('routes/Projects/Projects'));
const ProjectsSinglePage = lazy(() => import('routes/ProjectsSingle/ProjectsSingle'));
const ContactPage = lazy(() => import('routes/Contact/Contact'));
const SocioPage = lazy(() => import('routes/Socios/LoginSocios'));
const SinglePage = lazy(() => import('routes/Socios/single-page'));
const CadastrePage = lazy(() => import('routes/Socios/Cadastre-se'));
const PageSelect = lazy(() => import('routes/Socios/PageSelect'));
const Ekkoar = lazy(() => import('routes/Ekkoar/Ekkoar'))

/** @var object */
const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    Component: HomePage,
  },
  {
    path: '/FAQ',
    exact: true,
    title: 'FAQ',
    Component: FAQ,
  },
  {
    path: '/quem-somos',
    exact: true,
    title: 'Quem Somos',
    Component: AboutPage,
  },
  {
    path: '/ekko-vendas',
    exact: true,
    title: 'Quem Somos - Imobiliárias Ekko',
    Component: AboutImobiliariasPage,
  },
  {
    path: '/ark',
    exact: true,
    title: 'Quem Somos - Ark',
    Component: AboutArk,
  },
  {
    path: '/dekor',
    exact: true,
    title: 'Quem Somos - Dekor',
    Component: AboutDekor,
  },
  {
    path: '/blog/:page?',
    exact: true,
    title: 'Ekko Blog',
    Component: BlogPage,
  },
  {
    path: '/blog/post/:slug',
    exact: true,
    Component: BlogPostPage,
  },
  {
    path: '/empreendimentos/:page?',
    exact: true,
    title: 'Empreendimentos',
    Component: ProjectsPage,
  },
  {
    path: '/empreendimentos/post/:slug',
    exact: true,
    Component: ProjectsSinglePage,
  },
  {
    path: '/fale-conosco/:subpage?',
    exact: true,
    title: 'Fale Conosco',
    Component: ContactPage,
  },
  {
    path: '/Painel-Socios',
    exact: true,
    title: 'Painel de Sócios',
    Component: SocioPage,
  },
  {
    path: '/painel-select',
    exact: true,
    title: 'Painel de Sócios',
    Component: PageSelect,
  },
  {
    path: '/Painel-Socios/BI/:url/:title',
    exact: true,
    title: 'Painel Power BI',
    Component: SinglePage,
  },
  {
    path: '/sustentabilidade',
    exact: true,
    title: 'Ekkoar',
    Component: Ekkoar,
  },
];

export default routes;
