import React, {useState} from 'react';
import { isMobile } from 'react-device-detect';

import './style.scss'
// import { Container } from './styles';

function Popup({ setter }) {
  const [isVisible, setIsVisible] = useState(!getCookie('ekkoPrivacy'))

  function setCookie(name,value,days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  function acceptCookies() {
    setCookie('ekkoPrivacy', true, 30)
    setIsVisible(false)
  }

  return (
    <div className={`container-popup ${isMobile ? 'mobile' : ''}`} style={isVisible ? {display: 'flex'} : {display: 'none'}}>
      <div className="popup">
        <p>
          Utilizamos cookies e tecnologias semelhantes de acordo com a nossa <span onClick={() => setter(true)}>Política de Privacidade</span>
          , ao continuar navegando, você concorda com estas condições. 
        </p>
        <button onClick={() => acceptCookies()}>Concordar e fechar</button>
      </div>
    </div>
  );
}

export default Popup;