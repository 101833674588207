import React, { useState } from 'react';

import {
  Link,
  NavLink,
} from 'react-router-dom';

import LogoImage from 'assets/img/prod/globals/logo-mobile.png';
import MenuBackgroundImage from 'assets/img/prod/globals/menu-background.jpg';

import { ReactComponent as MenuTogglerIcon } from 'assets/icons/globals/menu-toggler.svg';
import { ReactComponent as MenuCloseIcon } from 'assets/icons/globals/menu-close.svg';
import { ReactComponent as ExternalIcon } from 'assets/icons/components/header/external.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/globals/social-facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/globals/social-instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/globals/social-linkedin.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/globals/social-youtube.svg';
import { ReactComponent as PinterestIcon } from 'assets/icons/globals/social-pinterest.svg';

const Header = () => {
  /** @state boolean */
  const [menuIsOpen, setMenuOpen] = useState(false);

  /** @var object */
  const socialLinks = [
    {
      title: 'Curta no Facebook',
      icon: <FacebookIcon />,
      href: 'https://facebook.com',
    },
    {
      title: 'Siga no Instagram',
      icon: <InstagramIcon />,
      href: 'https://instagram.com',
    },
    {
      title: 'Siga no Linkedin',
      icon: <LinkedinIcon />,
      href: 'https://linkedin.com',
    },
    {
      title: 'Inscreva-se no YouTube',
      icon: <YouTubeIcon />,
      href: 'https://youtube.com',
    },
    {
      title: 'Siga no Pinterest',
      icon: <PinterestIcon />,
      href: 'https://br.pinterest.com/ekkogroupoficial/',
    },
  ];

  /** @var object */
  const menuItems = [
    {
      title: 'Home',
      to: '/',
      exact: true,
    },
    {
      title: 'Corretores e Imobiliárias',
      to: '/fale-conosco/corretores-e-imobiliarias',
      exact: true,
    },
    {
      title: 'Empreendimentos',
      to: '/empreendimentos',
    },
    {
      title: 'Quem Somos',
      to: '/quem-somos',
      exact: true,
      children: [
        {
          title: 'Ekko Group',
          to: '/quem-somos',
        },
        {
          title: 'Ekko Blog',
          to: '/blog',
        },
        {
          title: 'Ekko.ong',
          to: 'http://ekkoong.com.br',
          external: true,
        },
        {
          title: 'Ekko Vendas',
          to: '/ekko-vendas',
        },
        {
          title: 'Ekko Dekor',
          to: '/dekor',
        },
        {
          title: 'Sustentabilidade',
          to: '/sustentabilidade',
          external: true,
        },
      ],
    },
    {
      title: 'Blog',
      to: '/blog',
    },
    {
      title: "Contato",
      to: "/fale-conosco",
      action: () => {
        let idProduto = document.getElementById("hypnoboxid");
        let hypnoId = "20";
        if (idProduto) {
          hypnoId = idProduto.value;
        }
        window.IdProduto = hypnoId;
        window.location = "/fale-conosco?idprod=" + hypnoId;
      },
    },
  ];

  /**
   * renderMenuItem
   *
   * Renders the specified menu item.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object item
   * @param boolean isChild
   * @return JSX
   */
  const renderMenuItem = (item, isChild) => {
    const className = isChild
      ? 'child-link'
      : 'menu__item-link';

    return item.external ? (
      <a
        href={item.to}
        rel="noopener noreferrer"
        target="_blank"
        className={className}
        onClick={() => setMenuOpen(false)}>
        {item.title}
        <ExternalIcon />
      </a>
    ) : (
      <NavLink
        to={item.to}
        exact={item.exact}
        className={className}
        onClick={ item.action? ()=> item.action():() => setMenuOpen(false)}>
        {item.title}
      </NavLink>
    );
  }

  // render
  return (
    <header id="mainHeader" className={`mobile ${menuIsOpen ? 'menu-open' : ''}`.trim()}>
      <div className="bar">
        <div className="logo">
          <Link
            to="/"
            className="logo__link">
            <img
              src={LogoImage}
              alt="Ekko Group"
              className="el-img"
            />
          </Link>
        </div>

        <div className="menu-toggler">
          <button
            type="button"
            className="menu-toggler__button"
            onClick={() => setMenuOpen(!menuIsOpen)}>
            <span className="open">
              <MenuTogglerIcon />
            </span>
            <span className="close">
              <MenuCloseIcon />
            </span>
          </button>
        </div>
      </div>

      <div className="mobile-menu">
        <div className="background">
          <img
            src={MenuBackgroundImage}
            alt="Ekko Group - Menu"
            className="el-img el-img__cover"
          />
        </div>

        <div className="menu-content">
          <div className="menu-items">
            <ul>
              {menuItems.map((item, itemKey) => (
                <li
                  key={itemKey}
                  className="menu__item">
                  {renderMenuItem(item)}

                  {item.children &&
                    <ul className="menu__item-children">
                      {item.children.map((child, childKey) => (
                        <li
                          key={childKey}
                          className="child">
                          {renderMenuItem(child, true)}
                        </li>
                      ))}
                    </ul>
                  }
                </li>
              ))}
            </ul>
          </div>

          <nav className="social-links">
            <ul>
              {socialLinks.map((item, key) => (
                <li
                  key={key}
                  className="item">
                  <a
                    href={item.href}
                    title={item.title}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="item__link">
                    {item.icon}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
