import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ history, children }) => {
  // scroll to top if it's a different page
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (
        window.location.href.indexOf('#') === -1
      ) {
        window.scrollTo(0, 0);
      } else {
        const section = document.getElementById(
          window.location.hash.replace('#', '')
        );

        if (section) {
          const { offsetTop } = section;
          window.scrollTo(0, offsetTop - 150);
        }
      }
    });

    return () => unlisten();
  }, [history]);

  return children;
}

export default withRouter(ScrollToTop);
