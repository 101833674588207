import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Field from './Field';
import SelectField from './SelectField';

const Form = forwardRef((props, ref) => {
  const {
    noStyle = false,
    uniqueId,
    onSubmit,
    hideLoader,
    loaderInitialMessage,
    Formcaptcha = true,
    AcceptPrivacyText
  } = props;

  /** @ref Form */
  const theForm = useRef();

  /** @state object */
  const [formFields, setFormFields] = useState({});

  /** @state object */
  const [invalidFields, setInvalidFields] = useState([]);

  /** @state object */
  const [children, setChildren] = useState([]);

  /** @state object */
  const [formData, updateFormData] = useState({});

  /** @state boolean */
  const [formLoaderIsOpen, setFormLoaderIsOpen] = useState(false);

  /** @state JSX */
  const [loaderMessage, setLoaderMessage] = useState(
    loaderInitialMessage || (
        <>
          <p>Enviando mensagem...</p>
          <p>Por favor, aguarde.</p>
        </>
    )
  );

  const [recaptcha, setRecaptcha] = useState(true)
  const [privacyText, setPrivacyText] = useState(AcceptPrivacyText)

  // prepare children
  useEffect(() => {
    const prepareChildren = childrenList => {
      return React.Children.toArray(childrenList).map(child => {
        if (typeof child === 'string') return child;

        // check if this child is a form field
        const isFormField = (
          typeof child.type === 'function' &&
          child.type.formField
        );

        // check if the field is invalid
        const fieldIsInvalid = isFormField && invalidFields
          .indexOf(child.props.name) !== -1;

        // add extra props
        const childProps = isFormField ? {
          uniqueId,
          formData,
          invalid: fieldIsInvalid,
          updateValue: updateFormData,
        } : {
          ...(child.props.children && {
            children: prepareChildren(child.props.children)
          }),
        }

        // save form field information
        if (isFormField) setFormFields(
          prevFormFields => ({
            ...prevFormFields,
            [child.props.name]: {
              type: child.props.type || false,
              required: child.props.required || false,
            }
          })
        );

        // return cloned child
        return React.cloneElement(child, childProps);
      });
    }

    setChildren(prepareChildren(props.children));

    // if(Formcaptcha !== undefined) {
    //   setRecaptcha(Formcaptcha)
    // }

    if(AcceptPrivacyText !== undefined) {
      setPrivacyText(AcceptPrivacyText)
    }
  }, [props.children, invalidFields, formData, uniqueId, Formcaptcha]);

  /**
   * validateFormData
   *
   * Validates the form data.
   *
   * @type function
   * @since 0.0.1
   *
   * @param NA
   * @return boolean
   */
  const validateFormData = () => {
    const invalidFields = [];

    for (let name in formFields) {
      const { type, required } =
        formFields[name];

      // get the value
      let value = formData[name];
      if (typeof value === 'string') {
        value = value.replace(/_/g, '');
      } else {
        value = `${value}`;
      }

      if (required && value.length === 0) {
        invalidFields.push(name);
        continue;
      }

      if (required || value.length !== 0) {
        let invalid = false;

        // phone
        if (type === 'phone') {
          invalid = value.length < 14;
        }

        if (invalid) invalidFields.push(name);
      }
    }

    setInvalidFields(invalidFields);
    return invalidFields.length === 0;
  }

  /**
   * submit
   *
   * Validates and returns the form data
   * to the parent component.
   *
   * @type function
   * @since 0.0.1
   *
   * @param object event
   * @return NA
   */
  const submit = event => {
    if (event) event.preventDefault();

    // if(!recaptcha) {
    //   return alert('Complete o captcha')
    // }

    if(!privacyText) {
      return alert('Por favor aceite os termos.')
    }

    if (theForm.current.disabled) {
      console.warn('Form is disabled.');
      return;
    }

    const formIsValid = validateFormData();
    if (formIsValid && onSubmit) {
      // disable the form
      theForm.current.disabled = true;

      // show loader
      if (!hideLoader) {
        setFormLoaderIsOpen(true)
        setLoaderMessage(
          loaderInitialMessage || (
            <>
              <p>Enviando mensagem...</p>
              <p>Por favor, aguarde.</p>
            </>
          )
        );
      };

      // submit the form
      const submission = onSubmit(formData, privacyText);
      // const submission = false;

      if (typeof submission === 'object') {
        submission.then(
          ({ success, message, reset }) => {
            // enable the form
            theForm.current.disabled = false;

            // reset
            if (reset) updateFormData(prevFormData => {
              for (let key in prevFormData)
                prevFormData[key] = '';
              return prevFormData;
            });

            // hide loader
            if (!hideLoader) {
              if (!message) message = success
                ? 'Enviando mensagem...'
                : 'Ocorreu um erro ao enviar a sua mensagem.';
              setLoaderMessage(message);
              setTimeout(() => setFormLoaderIsOpen(false), 2000);
            }
          }
        );
      } else {
        // enable the form
        theForm.current.disabled = false;
      }
    }
  }

  // make the submit method accessible
  // by the parent component
    useImperativeHandle(ref, () => ({ submit, formData }));

  // render
  return (
    <form
      ref={theForm}
      className={`form ${noStyle ? '' : 'styled'}`.trim()}
      onSubmit={submit}>
      {/* {(!hideLoader && Formcaptcha) && */}
      {(!hideLoader && privacyText) &&
      <div className={`form__loader ${formLoaderIsOpen ? 'open' : ''}`.trim()}>
        <div className="form__loader-message">
          {loaderMessage}
        </div>
      </div>
      }
      {children}
      {/* <div>
        <input value={privacyText} onChange={(e) => setPrivacyText(e.target.value)} type="checkbox" name="acceptTextPrivacy" id="acceptTextPrivacy" />
        <label htmlFor="acceptTextPrivacy">Li e concordo com a politica de privacidade</label>
      </div> */}
    </form>
  );
});

export default Form;
export { Field, SelectField };
